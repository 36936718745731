<template>
  <v-col
    cols="12"
    sm="6"
    md="2"
    lg="3"
  >
    <base-material-stats-card
      class="toggle-item"
      color="secondary"
      icon="mdi-store"
      :title="business.role || altRole"
      :top="business.business || business.name"
      :to="!isSuper ? `/adminis/business_/${business.businessId || business.id}`: `/business/childs/${business.businessId || business.id}`"
    />
  </v-col>
</template>

<script>
  export default {
    name: 'Business',

    props: {
      business: {
        type: Object,
        default: () => {},
      },
      altRole: {
        type: null || String,
        default: () => null,
      },
      isSuper: {
        type: Boolean,
        default: () => false,
      },
    },

    created () {
      // // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.business)
    },
  }
</script>

<style scoped>
.toggle-item {
  transform: scale(1);
  transition: 200ms all ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select:none;
}

.toggle-item:hover {
  transform: scale(1.05);
}

.toggle-item:active {
  transform: scale(1);
}
</style>
